import React from "react";
// import "./App.css";
import "./LoadingAnimation.css"; // Import CSS for animations

const App = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.text}>🎳 Bouvling? 🎳</h1>
        <div className="loading-spinner"></div> {/* Loading animation */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    margin: 0,
    padding: 0, // Fjern hvite rammer helt
    backgroundColor: "#1a1a1a", // Mørk bakgrunn
    boxSizing: "border-box", // Sørg for at margin/padding ikke legger til størrelse
  },
  content: {
    textAlign: "center",
  },
  text: {
    color: "#FFA500", // Oransje tekst
    fontSize: "2rem",
    fontFamily: "Poppins, sans-serif", // Bruk kul font
    marginBottom: "20px",
  },
};

export default App;